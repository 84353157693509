import React from 'react'
import { Text } from '@chakra-ui/react'
export const escRules = [
  // {
  //   contents: [
  //     'ด้วยคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย จะดำเนินการเลือกตั้งกรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ปีการศึกษา 2566 ',
  //     'เพื่อให้การเลือกตั้งฯ เป็นไปด้วยความเรียบร้อย คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย จึงให้มีประกาศเรื่อง การเลือกตั้งกรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ประจำปีการศึกษา 2566 ไว้ดังต่อไปนี้',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 1. ประกาศนี้เรียกว่า “ประกาศคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย เรื่อง การเลือกตั้งกรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ประจำปีการศึกษา 2566"',
  //   contents: [],
  // },
  // {
  //   header: 'ข้อ 2 ประกาศนี้ให้ใช้บังคับตั้งแต่วันถัดจากวันประกาศเป็นต้นไป',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 3 ให้ยกเลิกประกาศคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย เรื่อง การเลือกตั้งกรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ประจำปีการศึกษา 2566',
  //   contents: [],
  // },
  // {
  //   header: 'ข้อ 4 ในประกาศนี้',
  //   contents: [
  //     '“คณะ” หมายความว่า คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย',
  //     '“นิสิต”	 หมายความว่า นิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ซึ่งกำลังศึกษาอยู่ในระดับปริญญาตรี',
  //     '“การเลือกตั้ง” หมายความว่า การเลือกตั้งกรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ปีการศึกษา 2566',
  //     '“คณะกรรมการอำนวยการ” หมายความว่า คณะกรรมการอำนวยการเลือกตั้ง กรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ปีการศึกษา 2566',
  //     '“คณะกรรมการดำเนินการ” หมายความว่า คณะกรรมการดำเนินการเลือกตั้ง กรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ปีการศึกษา 2566',
  //     '“กรรมการนิสิต” หมายความว่า กรรมการนิสิตคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ปีการศึกษา 2565',
  //   ],
  // },
  // {
  //   header: 'ข้อ 5 ให้ประธานคณะกรรมการอำนวยการรักษาการตามประกาศนี้',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 6 ให้มีการเลือกตั้งตำแหน่งหัวหน้านิสิตในวันพุธที่ 22 กุมภาพันธ์ 2566 ตั้งแต่เวลา 08.30 น. ถึง 17.30 น. ตามวิธีที่คณะกรรมการดำเนินการกำหนด',
  //   contents: [
  //     'คณะกรรมการดำเนินการอาจกำหนดให้การเลือกตั้งตามวรรคหนึ่งกระทำผ่านระบบอิเลกทรอนิกส์ได้ เท่าที่ไม่ขัดหรือแย้งต่อประกาศฉบับนี้',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 7 ให้นิสิตในปีการศึกษา 2565 เป็นผู้มีสิทธิออกเสียงลงคะแนนในการเลือกตั้ง',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 8 ให้คณะกรรมการดำเนินการ นับคะแนนทันทีภายหลังจากหมดเวลาออกเสียงลงคะแนน และให้ดำเนินการประกาศผลอย่างไม่เป็นทางการภายในวันเดียวกันผ่านทุกช่องทางสื่อสารของกรรมการนิสิต',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 9 นิสิตผู้สมัครรับเลือกตั้งตามข้อ 6 ต้องมีคุณสมบัติดังต่อไปนี้',
  //   contents: [
  //     '(1) ไม่เป็นนิสิตสภาพวิทยาทัณฑ์',
  //     '(2) ไม่เป็นนิสิตที่เคยถูกลงโทษตัดคะแนนความประพฤติ',
  //     '(3) ไม่เคยดำรงตำแหน่งตามข้อ 6',
  //     '(4) มีรหัสนิสิต 2 ตัวแรกเท่ากับ 63 หรือ 64',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 10 ให้นิสิตผู้มีคุณสมบัติตามข้อ 9 ที่มีความประสงค์จะสมัครรับเลือกตั้งตามข้อ 6 สมัครได้ตั้งแต่วันที่ 8 กุมภาพันธ์ 2566 จนถึงวันที่ 10 กุมภาพันธ์ 2566 เวลา 23.59 น. ตามช่องทางและรูปแบบที่คณะกรรมการดำเนินการกำหนด',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 11 ให้คณะกรรมการดำเนินการตรวจสอบคุณสมบัติผู้สมัครรับเลือกตั้งตามข้อ 10 และให้ประกาศรายชื่อผู้มีคุณสมบัติตามข้อ 9 ไม่เกินกว่า 24 ชั่วโมงหลังสิ้นสุดระยะเวลารับสมัคร ผ่านทุกช่องทางสื่อสารของกรรมการนิสิต',
  //   contents: [
  //     'หากคณะกรรมการดำเนินการตรวจสอบคุณสมบัติแล้วเห็นว่านิสิตผู้สมัครรับเลือกตั้งขาดคุณสมบัติ ให้แจ้งต่อนิสิตผู้สมัครให้ทราบเป็นหนังสือโดยเร็ว แต่ไม่ช้ากว่า 3 วันนับถัดจากวันที่คณะกรรมการดำเนินการตรวจสอบคุณสมบัติเรียบร้อย',
  //     'นิสิตผู้สมัครรับเลือกตั้งอาจอุทธรณ์ผลการพิจารณาคุณสมบัติตามวรรคสองได้ โดยจัดทำคำโต้แย้งพร้อมหลักฐานเป็นหนังสือต่อคณะกรรมการอำนวยการเลือกตั้งภายใน 3 วันนับถัดจากวันที่ได้รับแจ้งตามวรรคสอง',
  //     'ผลการพิจารณาของคณะกรรมการอำนวยการตามวรรคสามถือเป็นที่สิ้นสุด',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 12 เพื่อให้การเลือกตั้งเป็นไปตามกฎระเบียบที่คณะกรรมการอำนวยการและคณะกรรมการดำเนินการกำหนด ให้นิสิตผู้มีรายชื่อตามข้อ 11 วรรคหนึ่งรายงานตัวเพื่อรับฟังคำชี้แจงจากคณะกรรมการดำเนินการ',
  //   contents: [
  //     'วัน เวลา และวิธีการรายงานตัวตามวรรคหนึ่งให้เป็นไปตามที่คณะกรรมการดำเนินการกำหนด',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 13 ห้ามมิให้นิสิตผู้มีรายชื่อตามข้อ 11 วรรคหนึ่งถอนการสมัครรับเลือกตั้ง เว้นแต่จะได้รับอนุญาตจากคณะกรรมการอำนวยการเลือกตั้ง',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 14 นิสิตอาจดำเนินการโฆษณาชวนเชื่อให้ผู้มีสิทธิลงคะแนนออกเสียงให้นิสิตผู้มีรายชื่อตามข้อ 11 วรรคหนึ่งได้ตามที่คณะกรรมการดำเนินการเลือกตั้งกำหนด',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 15 ให้คณะกรรมการดำเนินการเลือกตั้ง ประกาศผู้มีสิทธิออกเสียงลงคะแนนภายในวันที่ 20 กุมภาพันธ์ 2566',
  //   contents: [
  //     'นิสิตซึ่งไม่มีรายชื่อตามประกาศในวรรคหนึ่ง อาจอุทธรณ์ต่อคณะกรรมการอำนวยการเลือกตั้งพร้อมแนบหลักฐานรับรองสถานภาพนิสิตเป็นหนังสือภายในวันที่ 21 กุมภาพันธ์ 2566',
  //     'ผลการพิจารณาของคณะกรรมการอำนวยการตามวรรคสองถือเป็นที่สิ้นสุด',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 16 ให้คณะกรรมการดำเนินการ ยืนยันตัวตนนิสิตก่อนดำเนินการออกเสียงลงคะแนน',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 17 ให้นิสิตผู้ออกเสียงลงคะแนนตรวจสอบข้อมูล ชื่อ นามสกุล รหัสนิสิต และชั้นปีให้ถูกต้องก่อนการลงคะแนน',
  //   contents: [
  //     'หากนิสิตผู้ออกเสียงลงคะแนนพบว่าข้อมูลตามวรรคหนึ่งไม่ถูกต้อง ให้ดำเนินการแจ้งต่อคณะกรรมการดำเนินการในทันที',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 18 ให้บัตรออกเสียงลงคะแนน อย่างน้อยประกอบไปด้วยข้อมูลนิสิตผู้สมัครตามข้อ 11 วรรคหนึ่ง ดังนี้',
  //   contents: [
  //     '18.1 ชื่อ',
  //     '18.2 นามสกุล',
  //     '18.3 รูปถ่าย',
  //     '18.4 หมายเลขผู้สมัคร',
  //   ],
  // },
  // {
  //   header:
  //     'ข้อ 19 ให้นิสิตออกเสียงลงคะแนนเลือกตั้งตามวิธีที่คณะกรรมการดำเนินการกำหนด',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 20 ให้คณะกรรมการดำเนินการ รายงานผลการเลือกตั้งต่อคณะกรรมการอำนวยการไม่เกินกว่า 7 วันภายหลังสิ้นสุดการออกเสียงลงคะแนน',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 21 นิสิตผู้มีรายชื่อตามข้อ 11 วรรคหนึ่งจะได้รับการเลือกตั้งเป็นหัวหน้านิสิตก็ต่อเมื่อได้รับคะแนนมากกว่าผู้มีรายชื่อตามข้อ 11 วรรคหนึ่งทุกคนและมากกว่าคะแนนงดออกเสียง หรือหากผู้มีรายชื่อตามข้อ 11 วรรคหนึ่งมีไม่เกินกว่าหนึ่งคน ผู้มีรายชื่อตามข้อ 11 วรรคหนึ่งผู้นั้นจะต้องได้รับคะแนนเสียงรับรองไม่น้อยกว่าหนึ่งในสี่ของผู้มาใช้สิทธิออกเสียงลงคะแนนทั้งหมด และได้รับคะแนนเสียงรับรองมากกว่าไม่รับรอง',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 22 ให้นิสิตที่ประสงค์จะคัดค้านผลการเลือกตั้ง คัดค้านต่อคณะกรรมการอำนวยการ เป็นหนังสือพร้อมหลักฐานประกอบคำคัดค้านตามรูปแบบที่คณะกรรมการอำนวยการกำหนด ไม่เกินกว่า 7 วันภายหลังสิ้นสุดการออกเสียงลงคะแนน',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 23 เมื่อคณะกรรมการอำนวยการพิจารณาคำคัดค้านตามข้อ 21 เรียบร้อย ให้คณะกรรมการอำนวยการรับรองผลการเลือกตั้งและเสนอต่อคณะเพื่อประกาศผลการเลือกตั้งต่อไป',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 24 ในกรณีที่มีปัญหาเกี่ยวกับการเลือกตั้งตามประกาศฉบับนี้ ให้คณะกรรมการอำนวยการเป็นผู้วินิจฉัยชี้ขาด',
  //   contents: ['มติของคณะกรรมการอำนวยการตามวรรคหนึ่งให้ถือเป็นที่สิ้นสุด'],
  // },
  // {
  //   header:
  //     'ข้อ 25	นิสิตผู้ใดฝ่าฝืนประกาศฉบับนี้ ประกาศหรือคำสั่งของคณะกรรมการอำนวยการหรือคณะกรรมการดำเนินการ หรือกระทำการอันเป็นการทุจริตในการเลือกตั้ง  ให้คณะกรรมการอำนวยการพิจารณาโทษตามระเบียบจุฬาลงกรณ์มหาวิทยาลัย ว่าด้วยวินัยนิสิต พ.ศ. 2527 และที่แก้ไขเพิ่มเติม และเสนอต่อคณะเพื่อสั่งลงโทษต่อไป',
  //   contents: [],
  // },
  // {
  //   header:
  //     'ข้อ 26 ในกรณีที่คณะกรรมการอำนวยการหรือคณะกรรมการดำเนินการหรือทั้งคู่ สิ้นสุดลงก่อนการรับรองผลการเลือกตั้ง ให้รองคณบดีฝ่ายกิจการนิสิตรักษาการแทนคณะกรรมการอำนวยการหรือคณะกรรมการดำเนินการหรือทั้งคู่',
  //   contents: [],
  // },
]
